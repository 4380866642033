/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizeContainer200Response,
  CreateAdmin201Response,
  CreateAdminRequest,
  CreateAgentRequest,
  CreateDatasource201Response,
  CreateDatasourceRequest,
  CreateTemplate201Response,
  CreateTemplateFields201Response,
  CreateTemplateFieldsRequestInner,
  CreateTemplateRequest,
  DatasourceVisibility200Response,
  DatasourceVisibilityRequest,
  DeleteDataSource200Response,
  DeleteDataSource400Response,
  DeleteRecord200Response,
  EditFieldKindRequest,
  EditPolicy200Response,
  EditPolicyRequest,
  EditTemplate200Response,
  EditTemplateFieldRequest,
  EditTemplateMap200Response,
  EditTemplateMapRequest,
  EditTemplateRequest,
  ExtendFieldKind201Response,
  ExtendFieldKindRequest,
  ExtendPolicy201Response,
  ExtendPolicyRequest,
  GetAdmins200Response,
  GetAgents200Response,
  GetAgents200ResponseAgentsInner,
  GetAllAgentsVersions200ResponseInner,
  GetConfiguration200Response,
  GetContainer200Response,
  GetDatasource200Response,
  GetDatasource200ResponseMappedTemplatesInner,
  GetDatasourceStatistics200Response,
  GetDatasourceUploadLink200Response,
  GetFieldKind200Response,
  GetFieldKinds200Response,
  GetFileStatus200Response,
  GetLinkedOrgs200Response,
  GetLive200Response,
  GetLoginLink200Response,
  GetLogoutLink200Response,
  GetOrgDetails200Response,
  GetPolicies200Response,
  GetPolicies200ResponsePoliciesInner,
  GetPolicyStatistics200Response,
  GetTemplateRules200Response,
  GetTemplateStatistics200Response,
  IngestDatasourceLog200Response,
  ListDatasources200Response,
  ListTemplates200Response,
  LoginSuccess200Response,
  MapTemplate201Response,
  MapTemplateRequest,
  OrgOptionsGet200Response,
  OrgPortalError,
  OrgsGet200Response,
  RegisterNewOrg201Response,
  RegisterNewOrgRequest,
  ReorderTemplateFieldsRequest,
  Search200Response,
  SearchRequest,
  UpdateAgent200Response,
  UpdateAgentRequest,
  UpdateDatasourceRequest,
} from '../models/index';
import {
    AuthorizeContainer200ResponseFromJSON,
    AuthorizeContainer200ResponseToJSON,
    CreateAdmin201ResponseFromJSON,
    CreateAdmin201ResponseToJSON,
    CreateAdminRequestFromJSON,
    CreateAdminRequestToJSON,
    CreateAgentRequestFromJSON,
    CreateAgentRequestToJSON,
    CreateDatasource201ResponseFromJSON,
    CreateDatasource201ResponseToJSON,
    CreateDatasourceRequestFromJSON,
    CreateDatasourceRequestToJSON,
    CreateTemplate201ResponseFromJSON,
    CreateTemplate201ResponseToJSON,
    CreateTemplateFields201ResponseFromJSON,
    CreateTemplateFields201ResponseToJSON,
    CreateTemplateFieldsRequestInnerFromJSON,
    CreateTemplateFieldsRequestInnerToJSON,
    CreateTemplateRequestFromJSON,
    CreateTemplateRequestToJSON,
    DatasourceVisibility200ResponseFromJSON,
    DatasourceVisibility200ResponseToJSON,
    DatasourceVisibilityRequestFromJSON,
    DatasourceVisibilityRequestToJSON,
    DeleteDataSource200ResponseFromJSON,
    DeleteDataSource200ResponseToJSON,
    DeleteDataSource400ResponseFromJSON,
    DeleteDataSource400ResponseToJSON,
    DeleteRecord200ResponseFromJSON,
    DeleteRecord200ResponseToJSON,
    EditFieldKindRequestFromJSON,
    EditFieldKindRequestToJSON,
    EditPolicy200ResponseFromJSON,
    EditPolicy200ResponseToJSON,
    EditPolicyRequestFromJSON,
    EditPolicyRequestToJSON,
    EditTemplate200ResponseFromJSON,
    EditTemplate200ResponseToJSON,
    EditTemplateFieldRequestFromJSON,
    EditTemplateFieldRequestToJSON,
    EditTemplateMap200ResponseFromJSON,
    EditTemplateMap200ResponseToJSON,
    EditTemplateMapRequestFromJSON,
    EditTemplateMapRequestToJSON,
    EditTemplateRequestFromJSON,
    EditTemplateRequestToJSON,
    ExtendFieldKind201ResponseFromJSON,
    ExtendFieldKind201ResponseToJSON,
    ExtendFieldKindRequestFromJSON,
    ExtendFieldKindRequestToJSON,
    ExtendPolicy201ResponseFromJSON,
    ExtendPolicy201ResponseToJSON,
    ExtendPolicyRequestFromJSON,
    ExtendPolicyRequestToJSON,
    GetAdmins200ResponseFromJSON,
    GetAdmins200ResponseToJSON,
    GetAgents200ResponseFromJSON,
    GetAgents200ResponseToJSON,
    GetAgents200ResponseAgentsInnerFromJSON,
    GetAgents200ResponseAgentsInnerToJSON,
    GetAllAgentsVersions200ResponseInnerFromJSON,
    GetAllAgentsVersions200ResponseInnerToJSON,
    GetConfiguration200ResponseFromJSON,
    GetConfiguration200ResponseToJSON,
    GetContainer200ResponseFromJSON,
    GetContainer200ResponseToJSON,
    GetDatasource200ResponseFromJSON,
    GetDatasource200ResponseToJSON,
    GetDatasource200ResponseMappedTemplatesInnerFromJSON,
    GetDatasource200ResponseMappedTemplatesInnerToJSON,
    GetDatasourceStatistics200ResponseFromJSON,
    GetDatasourceStatistics200ResponseToJSON,
    GetDatasourceUploadLink200ResponseFromJSON,
    GetDatasourceUploadLink200ResponseToJSON,
    GetFieldKind200ResponseFromJSON,
    GetFieldKind200ResponseToJSON,
    GetFieldKinds200ResponseFromJSON,
    GetFieldKinds200ResponseToJSON,
    GetFileStatus200ResponseFromJSON,
    GetFileStatus200ResponseToJSON,
    GetLinkedOrgs200ResponseFromJSON,
    GetLinkedOrgs200ResponseToJSON,
    GetLive200ResponseFromJSON,
    GetLive200ResponseToJSON,
    GetLoginLink200ResponseFromJSON,
    GetLoginLink200ResponseToJSON,
    GetLogoutLink200ResponseFromJSON,
    GetLogoutLink200ResponseToJSON,
    GetOrgDetails200ResponseFromJSON,
    GetOrgDetails200ResponseToJSON,
    GetPolicies200ResponseFromJSON,
    GetPolicies200ResponseToJSON,
    GetPolicies200ResponsePoliciesInnerFromJSON,
    GetPolicies200ResponsePoliciesInnerToJSON,
    GetPolicyStatistics200ResponseFromJSON,
    GetPolicyStatistics200ResponseToJSON,
    GetTemplateRules200ResponseFromJSON,
    GetTemplateRules200ResponseToJSON,
    GetTemplateStatistics200ResponseFromJSON,
    GetTemplateStatistics200ResponseToJSON,
    IngestDatasourceLog200ResponseFromJSON,
    IngestDatasourceLog200ResponseToJSON,
    ListDatasources200ResponseFromJSON,
    ListDatasources200ResponseToJSON,
    ListTemplates200ResponseFromJSON,
    ListTemplates200ResponseToJSON,
    LoginSuccess200ResponseFromJSON,
    LoginSuccess200ResponseToJSON,
    MapTemplate201ResponseFromJSON,
    MapTemplate201ResponseToJSON,
    MapTemplateRequestFromJSON,
    MapTemplateRequestToJSON,
    OrgOptionsGet200ResponseFromJSON,
    OrgOptionsGet200ResponseToJSON,
    OrgPortalErrorFromJSON,
    OrgPortalErrorToJSON,
    OrgsGet200ResponseFromJSON,
    OrgsGet200ResponseToJSON,
    RegisterNewOrg201ResponseFromJSON,
    RegisterNewOrg201ResponseToJSON,
    RegisterNewOrgRequestFromJSON,
    RegisterNewOrgRequestToJSON,
    ReorderTemplateFieldsRequestFromJSON,
    ReorderTemplateFieldsRequestToJSON,
    Search200ResponseFromJSON,
    Search200ResponseToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    UpdateAgent200ResponseFromJSON,
    UpdateAgent200ResponseToJSON,
    UpdateAgentRequestFromJSON,
    UpdateAgentRequestToJSON,
    UpdateDatasourceRequestFromJSON,
    UpdateDatasourceRequestToJSON,
} from '../models/index';

export interface AcceptContainerRequest {
    orgID: string;
    containerID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface ApproveAgentVersionRequest {
    orgID: string;
    agentID: string;
    versionID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface AuthorizeContainerRequest {
    orgID: string;
    containerID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface CreateAdminOperationRequest {
    orgID: string;
    createAdminRequest: CreateAdminRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface CreateAgentOperationRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    limit?: number;
    properties?: CreateAgentRequest;
}

export interface CreateDatasourceOperationRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    datasource?: CreateDatasourceRequest;
}

export interface CreateTemplateOperationRequest {
    orgID: string;
    templateID: string;
    extendTemplateRequest: CreateTemplateRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface CreateTemplateFieldsRequest {
    orgID: string;
    templateID: string;
    addTemplateFields: Array<CreateTemplateFieldsRequestInner>;
    authorization?: string;
    xSessionStore?: string;
}

export interface DatasourceVisibilityOperationRequest {
    orgID: string;
    datasourceID: string;
    properties: DatasourceVisibilityRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface DeleteContainerRequest {
    orgID: string;
    containerID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface DeleteDataSourceRequest {
    orgID: string;
    datasourceID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface DeleteRecordRequest {
    orgID: string;
    containerID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface EditFieldKindOperationRequest {
    orgID: string;
    fieldKindID: string;
    editFieldKindRequest: EditFieldKindRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface EditPolicyOperationRequest {
    orgID: string;
    policyID: string;
    authorization?: string;
    xSessionStore?: string;
    policy?: EditPolicyRequest;
}

export interface EditTemplateOperationRequest {
    orgID: string;
    templateID: string;
    editTemplateRequest: EditTemplateRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface EditTemplateFieldOperationRequest {
    orgID: string;
    templateID: string;
    templateFieldID: string;
    editTemplateField: EditTemplateFieldRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface EditTemplateMapOperationRequest {
    orgID: string;
    templateID: string;
    datasourceID: string;
    properties: EditTemplateMapRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface ExtendFieldKindOperationRequest {
    orgID: string;
    fieldKindID: string;
    extendFieldKindRequest: ExtendFieldKindRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface ExtendPolicyOperationRequest {
    orgID: string;
    properties: ExtendPolicyRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetAdminsRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    limit?: number;
    start?: string;
    end?: string;
}

export interface GetAgentRequest {
    orgID: string;
    agentID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetAgentVersionsRequest {
    orgID: string;
    agentID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetAgentsRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    limit?: number;
}

export interface GetAgentsLinkedToEntityRequest {
    orgID: string;
    entityID: string;
    authorization?: string;
    xSessionStore?: string;
    sortBy?: GetAgentsLinkedToEntitySortByEnum;
    limit?: number;
    start?: string;
    end?: string;
}

export interface GetAllAgentsVersionsRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    ownerID?: string;
    includeApprovedAgents?: boolean;
    includeApprovedVersions?: boolean;
    versionsPerAgent?: number;
    sortBy?: GetAllAgentsVersionsSortByEnum;
    limit?: number;
    start?: string;
    end?: string;
}

export interface GetContainerRequest {
    orgID: string;
    containerID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetContainersRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    aggregateID?: string;
    completed?: boolean;
    issuerID?: string;
    recipientID?: string;
    templateID?: string;
    templateType?: GetContainersTemplateTypeEnum;
    limit?: number;
    start?: string;
    end?: string;
    sortBy?: GetContainersSortByEnum;
    state?: GetContainersStateEnum;
}

export interface GetDatasourceRequest {
    orgID: string;
    datasourceID: string;
    authorization?: string;
    xSessionStore?: string;
    mappedTemplatesLimit?: number;
    errorLimit?: number;
    errorStart?: string;
}

export interface GetDatasourceErrorsRequest {
    orgID: string;
    datasourceID: string;
    authorization?: string;
    xSessionStore?: string;
    start?: string;
    end?: string;
}

export interface GetDatasourceStatisticsRequest {
    orgID: string;
    datasourceID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetDatasourceUploadLinkRequest {
    orgID: string;
    datasourceID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetFieldKindRequest {
    orgID: string;
    fieldKindID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetFieldKindsRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    ownerID?: string;
    parentID?: string;
    limit?: number;
    start?: string;
    end?: string;
}

export interface GetFileStatusRequest {
    orgID: string;
    correlationID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetLinkedOrgsRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetLoginLinkRequest {
    host?: string;
}

export interface GetLogoutLinkRequest {
    host?: string;
}

export interface GetOrgDetailsRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetPoliciesRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    state?: GetPoliciesStateEnum;
    ownerID?: string;
    limit?: number;
    start?: string;
    end?: string;
}

export interface GetPolicyRequest {
    orgID: string;
    policyID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetPolicyStatisticsRequest {
    orgID: string;
    policyID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetTemplateRequest {
    orgID: string;
    templateID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetTemplateRulesRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface GetTemplateStatisticsRequest {
    orgID: string;
    templateID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface IngestDatasourceLogRequest {
    orgID: string;
    datasourceID: string;
    correlationID: string;
    adminEmail: string;
    fileName: string;
    fileUrl: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface ListDatasourcesRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    limit?: number;
    start?: string;
    end?: string;
}

export interface ListTemplatesRequest {
    orgID: string;
    authorization?: string;
    xSessionStore?: string;
    ownerID?: string;
    type?: ListTemplatesTypeEnum;
    templateFieldId?: Array<string>;
    status?: ListTemplatesStatusEnum;
    limit?: number;
    start?: string;
    end?: string;
}

export interface LoginSuccessRequest {
    code: string;
    state: string;
}

export interface MapTemplateOperationRequest {
    orgID: string;
    templateID: string;
    datasourceID: string;
    templateMappingRequest: MapTemplateRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface OrgOptionsGetRequest {
    authorization?: string;
}

export interface OrgsGetRequest {
    authorization?: string;
    xSessionStore?: string;
}

export interface RegisterNewOrgOperationRequest {
    orgID: string;
    registerNewOrgRequest: RegisterNewOrgRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface RejectContainerRequest {
    orgID: string;
    containerID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface RemoveAdminRequest {
    orgID: string;
    adminID: string;
    authorization?: string;
    xSessionStore?: string;
}

export interface ReorderTemplateFieldsOperationRequest {
    orgID: string;
    templateID: string;
    authorization?: string;
    xSessionStore?: string;
    templateFieldReorderRequest?: ReorderTemplateFieldsRequest;
}

export interface RevokeContainerRequest {
    orgID: string;
    containerID: string;
}

export interface SearchOperationRequest {
    orgID: string;
    query: SearchRequest;
    authorization?: string;
    xSessionStore?: string;
}

export interface SelectOrgRequest {
    orgID: string;
    authorization?: string;
}

export interface UpdateAgentOperationRequest {
    orgID: string;
    agentID: string;
    authorization?: string;
    xSessionStore?: string;
    properties?: UpdateAgentRequest;
}

export interface UpdateDatasourceOperationRequest {
    orgID: string;
    datasourceID: string;
    authorization?: string;
    xSessionStore?: string;
    datasource?: UpdateDatasourceRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Accept a container that has been issued to the current org.
     */
    async acceptContainerRaw(requestParameters: AcceptContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizeContainer200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling acceptContainer.');
        }

        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling acceptContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/{containerID}/accept`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeContainer200ResponseFromJSON(jsonValue));
    }

    /**
     * Accept a container that has been issued to the current org.
     */
    async acceptContainer(requestParameters: AcceptContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizeContainer200Response> {
        const response = await this.acceptContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve Agent version
     */
    async approveAgentVersionRaw(requestParameters: ApproveAgentVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling approveAgentVersion.');
        }

        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling approveAgentVersion.');
        }

        if (requestParameters.versionID === null || requestParameters.versionID === undefined) {
            throw new runtime.RequiredError('versionID','Required parameter requestParameters.versionID was null or undefined when calling approveAgentVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/agents/{agentID}/version/{versionID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))).replace(`{${"versionID"}}`, encodeURIComponent(String(requestParameters.versionID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Approve Agent version
     */
    async approveAgentVersion(requestParameters: ApproveAgentVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.approveAgentVersionRaw(requestParameters, initOverrides);
    }

    /**
     * Authorize a container to be issued to it\'s recipient.
     */
    async authorizeContainerRaw(requestParameters: AuthorizeContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizeContainer200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling authorizeContainer.');
        }

        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling authorizeContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/{containerID}/authorize`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeContainer200ResponseFromJSON(jsonValue));
    }

    /**
     * Authorize a container to be issued to it\'s recipient.
     */
    async authorizeContainer(requestParameters: AuthorizeContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizeContainer200Response> {
        const response = await this.authorizeContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add an admin to an org.
     */
    async createAdminRaw(requestParameters: CreateAdminOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAdmin201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling createAdmin.');
        }

        if (requestParameters.createAdminRequest === null || requestParameters.createAdminRequest === undefined) {
            throw new runtime.RequiredError('createAdminRequest','Required parameter requestParameters.createAdminRequest was null or undefined when calling createAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/add-admin`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAdminRequestToJSON(requestParameters.createAdminRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAdmin201ResponseFromJSON(jsonValue));
    }

    /**
     * Add an admin to an org.
     */
    async createAdmin(requestParameters: CreateAdminOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAdmin201Response> {
        const response = await this.createAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Agent
     */
    async createAgentRaw(requestParameters: CreateAgentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgents200ResponseAgentsInner>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling createAgent.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/agents`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAgentRequestToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgents200ResponseAgentsInnerFromJSON(jsonValue));
    }

    /**
     * Create Agent
     */
    async createAgent(requestParameters: CreateAgentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgents200ResponseAgentsInner> {
        const response = await this.createAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new datasource.  Support for JSON field types here is toggled by the `showJsonFieldTypes` launch darkly flag.
     */
    async createDatasourceRaw(requestParameters: CreateDatasourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDatasource201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling createDatasource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDatasourceRequestToJSON(requestParameters.datasource),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDatasource201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new datasource.  Support for JSON field types here is toggled by the `showJsonFieldTypes` launch darkly flag.
     */
    async createDatasource(requestParameters: CreateDatasourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDatasource201Response> {
        const response = await this.createDatasourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new template with a name and description
     */
    async createTemplateRaw(requestParameters: CreateTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTemplate201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling createTemplate.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling createTemplate.');
        }

        if (requestParameters.extendTemplateRequest === null || requestParameters.extendTemplateRequest === undefined) {
            throw new runtime.RequiredError('extendTemplateRequest','Required parameter requestParameters.extendTemplateRequest was null or undefined when calling createTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTemplateRequestToJSON(requestParameters.extendTemplateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new template with a name and description
     */
    async createTemplate(requestParameters: CreateTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTemplate201Response> {
        const response = await this.createTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new template with a name and description
     */
    async createTemplateFieldsRaw(requestParameters: CreateTemplateFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTemplateFields201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling createTemplateFields.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling createTemplateFields.');
        }

        if (requestParameters.addTemplateFields === null || requestParameters.addTemplateFields === undefined) {
            throw new runtime.RequiredError('addTemplateFields','Required parameter requestParameters.addTemplateFields was null or undefined when calling createTemplateFields.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}/add-fields`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.addTemplateFields.map(CreateTemplateFieldsRequestInnerToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTemplateFields201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new template with a name and description
     */
    async createTemplateFields(requestParameters: CreateTemplateFieldsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTemplateFields201Response> {
        const response = await this.createTemplateFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update datasource visibility
     */
    async datasourceVisibilityRaw(requestParameters: DatasourceVisibilityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasourceVisibility200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling datasourceVisibility.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling datasourceVisibility.');
        }

        if (requestParameters.properties === null || requestParameters.properties === undefined) {
            throw new runtime.RequiredError('properties','Required parameter requestParameters.properties was null or undefined when calling datasourceVisibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}/visibility`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DatasourceVisibilityRequestToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DatasourceVisibility200ResponseFromJSON(jsonValue));
    }

    /**
     * Update datasource visibility
     */
    async datasourceVisibility(requestParameters: DatasourceVisibilityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasourceVisibility200Response> {
        const response = await this.datasourceVisibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a container before issuing.
     */
    async deleteContainerRaw(requestParameters: DeleteContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizeContainer200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling deleteContainer.');
        }

        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling deleteContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/{containerID}/delete`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeContainer200ResponseFromJSON(jsonValue));
    }

    /**
     * Remove a container before issuing.
     */
    async deleteContainer(requestParameters: DeleteContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizeContainer200Response> {
        const response = await this.deleteContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a DataSource
     */
    async deleteDataSourceRaw(requestParameters: DeleteDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteDataSource200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling deleteDataSource.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling deleteDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteDataSource200ResponseFromJSON(jsonValue));
    }

    /**
     * Deletes a DataSource
     */
    async deleteDataSource(requestParameters: DeleteDataSourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteDataSource200Response> {
        const response = await this.deleteDataSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a target record for a given container.
     */
    async deleteRecordRaw(requestParameters: DeleteRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteRecord200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling deleteRecord.');
        }

        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling deleteRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/recordForContainer/{containerID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteRecord200ResponseFromJSON(jsonValue));
    }

    /**
     * Delete a target record for a given container.
     */
    async deleteRecord(requestParameters: DeleteRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteRecord200Response> {
        const response = await this.deleteRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit a specific field kind
     */
    async editFieldKindRaw(requestParameters: EditFieldKindOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFieldKind200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling editFieldKind.');
        }

        if (requestParameters.fieldKindID === null || requestParameters.fieldKindID === undefined) {
            throw new runtime.RequiredError('fieldKindID','Required parameter requestParameters.fieldKindID was null or undefined when calling editFieldKind.');
        }

        if (requestParameters.editFieldKindRequest === null || requestParameters.editFieldKindRequest === undefined) {
            throw new runtime.RequiredError('editFieldKindRequest','Required parameter requestParameters.editFieldKindRequest was null or undefined when calling editFieldKind.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/field-kinds/{fieldKindID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"fieldKindID"}}`, encodeURIComponent(String(requestParameters.fieldKindID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EditFieldKindRequestToJSON(requestParameters.editFieldKindRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFieldKind200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit a specific field kind
     */
    async editFieldKind(requestParameters: EditFieldKindOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFieldKind200Response> {
        const response = await this.editFieldKindRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit Policy
     */
    async editPolicyRaw(requestParameters: EditPolicyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditPolicy200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling editPolicy.');
        }

        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling editPolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/policies/{policyID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EditPolicyRequestToJSON(requestParameters.policy),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditPolicy200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit Policy
     */
    async editPolicy(requestParameters: EditPolicyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditPolicy200Response> {
        const response = await this.editPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit a template
     */
    async editTemplateRaw(requestParameters: EditTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditTemplate200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling editTemplate.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling editTemplate.');
        }

        if (requestParameters.editTemplateRequest === null || requestParameters.editTemplateRequest === undefined) {
            throw new runtime.RequiredError('editTemplateRequest','Required parameter requestParameters.editTemplateRequest was null or undefined when calling editTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditTemplateRequestToJSON(requestParameters.editTemplateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditTemplate200ResponseFromJSON(jsonValue));
    }

    /**
     * Edit a template
     */
    async editTemplate(requestParameters: EditTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditTemplate200Response> {
        const response = await this.editTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit a template field
     */
    async editTemplateFieldRaw(requestParameters: EditTemplateFieldOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling editTemplateField.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling editTemplateField.');
        }

        if (requestParameters.templateFieldID === null || requestParameters.templateFieldID === undefined) {
            throw new runtime.RequiredError('templateFieldID','Required parameter requestParameters.templateFieldID was null or undefined when calling editTemplateField.');
        }

        if (requestParameters.editTemplateField === null || requestParameters.editTemplateField === undefined) {
            throw new runtime.RequiredError('editTemplateField','Required parameter requestParameters.editTemplateField was null or undefined when calling editTemplateField.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}/fields/{templateFieldID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))).replace(`{${"templateFieldID"}}`, encodeURIComponent(String(requestParameters.templateFieldID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditTemplateFieldRequestToJSON(requestParameters.editTemplateField),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit a template field
     */
    async editTemplateField(requestParameters: EditTemplateFieldOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editTemplateFieldRaw(requestParameters, initOverrides);
    }

    /**
     * Updates a mapping for a template
     */
    async editTemplateMapRaw(requestParameters: EditTemplateMapOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditTemplateMap200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling editTemplateMap.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling editTemplateMap.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling editTemplateMap.');
        }

        if (requestParameters.properties === null || requestParameters.properties === undefined) {
            throw new runtime.RequiredError('properties','Required parameter requestParameters.properties was null or undefined when calling editTemplateMap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}/datasources/{datasourceID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EditTemplateMapRequestToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditTemplateMap200ResponseFromJSON(jsonValue));
    }

    /**
     * Updates a mapping for a template
     */
    async editTemplateMap(requestParameters: EditTemplateMapOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditTemplateMap200Response> {
        const response = await this.editTemplateMapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new field kind that extends a previous field kind
     */
    async extendFieldKindRaw(requestParameters: ExtendFieldKindOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendFieldKind201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling extendFieldKind.');
        }

        if (requestParameters.fieldKindID === null || requestParameters.fieldKindID === undefined) {
            throw new runtime.RequiredError('fieldKindID','Required parameter requestParameters.fieldKindID was null or undefined when calling extendFieldKind.');
        }

        if (requestParameters.extendFieldKindRequest === null || requestParameters.extendFieldKindRequest === undefined) {
            throw new runtime.RequiredError('extendFieldKindRequest','Required parameter requestParameters.extendFieldKindRequest was null or undefined when calling extendFieldKind.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/field-kinds/{fieldKindID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"fieldKindID"}}`, encodeURIComponent(String(requestParameters.fieldKindID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtendFieldKindRequestToJSON(requestParameters.extendFieldKindRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendFieldKind201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new field kind that extends a previous field kind
     */
    async extendFieldKind(requestParameters: ExtendFieldKindOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendFieldKind201Response> {
        const response = await this.extendFieldKindRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extend a Policy
     */
    async extendPolicyRaw(requestParameters: ExtendPolicyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendPolicy201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling extendPolicy.');
        }

        if (requestParameters.properties === null || requestParameters.properties === undefined) {
            throw new runtime.RequiredError('properties','Required parameter requestParameters.properties was null or undefined when calling extendPolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/policies`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtendPolicyRequestToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendPolicy201ResponseFromJSON(jsonValue));
    }

    /**
     * Extend a Policy
     */
    async extendPolicy(requestParameters: ExtendPolicyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendPolicy201Response> {
        const response = await this.extendPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of admins
     */
    async getAdminsRaw(requestParameters: GetAdminsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAdmins200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getAdmins.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/admins`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAdmins200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of admins
     */
    async getAdmins(requestParameters: GetAdminsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAdmins200Response> {
        const response = await this.getAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve an Agent by id
     */
    async getAgentRaw(requestParameters: GetAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgents200ResponseAgentsInner>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getAgent.');
        }

        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling getAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/agents/{agentID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgents200ResponseAgentsInnerFromJSON(jsonValue));
    }

    /**
     * Retrieve an Agent by id
     */
    async getAgent(requestParameters: GetAgentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgents200ResponseAgentsInner> {
        const response = await this.getAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a paginated list of a single agent\'s versions
     * Get Agent Versions
     */
    async getAgentVersionsRaw(requestParameters: GetAgentVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllAgentsVersions200ResponseInner>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getAgentVersions.');
        }

        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling getAgentVersions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/agents/{agentID}/versions`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllAgentsVersions200ResponseInnerFromJSON(jsonValue));
    }

    /**
     * Get a paginated list of a single agent\'s versions
     * Get Agent Versions
     */
    async getAgentVersions(requestParameters: GetAgentVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllAgentsVersions200ResponseInner> {
        const response = await this.getAgentVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve Agents
     */
    async getAgentsRaw(requestParameters: GetAgentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgents200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getAgents.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/agents`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgents200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve Agents
     */
    async getAgents(requestParameters: GetAgentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgents200Response> {
        const response = await this.getAgentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get agents linked to this entity
     */
    async getAgentsLinkedToEntityRaw(requestParameters: GetAgentsLinkedToEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAgents200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getAgentsLinkedToEntity.');
        }

        if (requestParameters.entityID === null || requestParameters.entityID === undefined) {
            throw new runtime.RequiredError('entityID','Required parameter requestParameters.entityID was null or undefined when calling getAgentsLinkedToEntity.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/entities/{entityID}/links`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"entityID"}}`, encodeURIComponent(String(requestParameters.entityID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAgents200ResponseFromJSON(jsonValue));
    }

    /**
     * Get agents linked to this entity
     */
    async getAgentsLinkedToEntity(requestParameters: GetAgentsLinkedToEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAgents200Response> {
        const response = await this.getAgentsLinkedToEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Agents versions.  Accessible to Sol admins only.
     */
    async getAllAgentsVersionsRaw(requestParameters: GetAllAgentsVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetAllAgentsVersions200ResponseInner>>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getAllAgentsVersions.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerID !== undefined) {
            queryParameters['ownerID'] = requestParameters.ownerID;
        }

        if (requestParameters.includeApprovedAgents !== undefined) {
            queryParameters['includeApprovedAgents'] = requestParameters.includeApprovedAgents;
        }

        if (requestParameters.includeApprovedVersions !== undefined) {
            queryParameters['includeApprovedVersions'] = requestParameters.includeApprovedVersions;
        }

        if (requestParameters.versionsPerAgent !== undefined) {
            queryParameters['versionsPerAgent'] = requestParameters.versionsPerAgent;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/agents/versions`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAllAgentsVersions200ResponseInnerFromJSON));
    }

    /**
     * Get all Agents versions.  Accessible to Sol admins only.
     */
    async getAllAgentsVersions(requestParameters: GetAllAgentsVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetAllAgentsVersions200ResponseInner>> {
        const response = await this.getAllAgentsVersionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get environment configuration
     */
    async getConfigurationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetConfiguration200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConfiguration200ResponseFromJSON(jsonValue));
    }

    /**
     * Get environment configuration
     */
    async getConfiguration(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetConfiguration200Response> {
        const response = await this.getConfigurationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Read data from a target container.
     */
    async getContainerRaw(requestParameters: GetContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetContainer200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getContainer.');
        }

        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling getContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/containers/{containerID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContainer200ResponseFromJSON(jsonValue));
    }

    /**
     * Read data from a target container.
     */
    async getContainer(requestParameters: GetContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetContainer200Response> {
        const response = await this.getContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Read data from a range of containers.
     */
    async getContainersRaw(requestParameters: GetContainersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgsGet200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getContainers.');
        }

        const queryParameters: any = {};

        if (requestParameters.aggregateID !== undefined) {
            queryParameters['aggregateID'] = requestParameters.aggregateID;
        }

        if (requestParameters.completed !== undefined) {
            queryParameters['completed'] = requestParameters.completed;
        }

        if (requestParameters.issuerID !== undefined) {
            queryParameters['issuerID'] = requestParameters.issuerID;
        }

        if (requestParameters.recipientID !== undefined) {
            queryParameters['recipientID'] = requestParameters.recipientID;
        }

        if (requestParameters.templateID !== undefined) {
            queryParameters['templateID'] = requestParameters.templateID;
        }

        if (requestParameters.templateType !== undefined) {
            queryParameters['templateType'] = requestParameters.templateType;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/containers`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgsGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Read data from a range of containers.
     */
    async getContainers(requestParameters: GetContainersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgsGet200Response> {
        const response = await this.getContainersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a datasource, pagination parameters can be supplied to limit the returned datasource/record errors.
     */
    async getDatasourceRaw(requestParameters: GetDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDatasource200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getDatasource.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling getDatasource.');
        }

        const queryParameters: any = {};

        if (requestParameters.mappedTemplatesLimit !== undefined) {
            queryParameters['mappedTemplatesLimit'] = requestParameters.mappedTemplatesLimit;
        }

        if (requestParameters.errorLimit !== undefined) {
            queryParameters['errorLimit'] = requestParameters.errorLimit;
        }

        if (requestParameters.errorStart !== undefined) {
            queryParameters['errorStart'] = requestParameters.errorStart;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDatasource200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a datasource, pagination parameters can be supplied to limit the returned datasource/record errors.
     */
    async getDatasource(requestParameters: GetDatasourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDatasource200Response> {
        const response = await this.getDatasourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a datasource ingestion errors, pagination parameters can be supplied to limit the returned datasource/record errors.
     */
    async getDatasourceErrorsRaw(requestParameters: GetDatasourceErrorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getDatasourceErrors.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling getDatasourceErrors.');
        }

        const queryParameters: any = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}/errors`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a datasource ingestion errors, pagination parameters can be supplied to limit the returned datasource/record errors.
     */
    async getDatasourceErrors(requestParameters: GetDatasourceErrorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getDatasourceErrorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statistics about a datasource\'s records
     */
    async getDatasourceStatisticsRaw(requestParameters: GetDatasourceStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDatasourceStatistics200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getDatasourceStatistics.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling getDatasourceStatistics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}/stats`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDatasourceStatistics200ResponseFromJSON(jsonValue));
    }

    /**
     * Get statistics about a datasource\'s records
     */
    async getDatasourceStatistics(requestParameters: GetDatasourceStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDatasourceStatistics200Response> {
        const response = await this.getDatasourceStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a link to upload files for the given datasource
     */
    async getDatasourceUploadLinkRaw(requestParameters: GetDatasourceUploadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDatasourceUploadLink200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getDatasourceUploadLink.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling getDatasourceUploadLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}/upload-link`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDatasourceUploadLink200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a link to upload files for the given datasource
     */
    async getDatasourceUploadLink(requestParameters: GetDatasourceUploadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDatasourceUploadLink200Response> {
        const response = await this.getDatasourceUploadLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a specific field kind
     */
    async getFieldKindRaw(requestParameters: GetFieldKindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFieldKind200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getFieldKind.');
        }

        if (requestParameters.fieldKindID === null || requestParameters.fieldKindID === undefined) {
            throw new runtime.RequiredError('fieldKindID','Required parameter requestParameters.fieldKindID was null or undefined when calling getFieldKind.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/field-kinds/{fieldKindID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"fieldKindID"}}`, encodeURIComponent(String(requestParameters.fieldKindID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFieldKind200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a specific field kind
     */
    async getFieldKind(requestParameters: GetFieldKindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFieldKind200Response> {
        const response = await this.getFieldKindRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query for field kinds owned by your org.
     */
    async getFieldKindsRaw(requestParameters: GetFieldKindsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFieldKinds200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getFieldKinds.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerID !== undefined) {
            queryParameters['ownerID'] = requestParameters.ownerID;
        }

        if (requestParameters.parentID !== undefined) {
            queryParameters['parentID'] = requestParameters.parentID;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/field-kinds`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFieldKinds200ResponseFromJSON(jsonValue));
    }

    /**
     * Query for field kinds owned by your org.
     */
    async getFieldKinds(requestParameters: GetFieldKindsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFieldKinds200Response> {
        const response = await this.getFieldKindsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the status and any error reports for a file upload
     */
    async getFileStatusRaw(requestParameters: GetFileStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFileStatus200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getFileStatus.');
        }

        if (requestParameters.correlationID === null || requestParameters.correlationID === undefined) {
            throw new runtime.RequiredError('correlationID','Required parameter requestParameters.correlationID was null or undefined when calling getFileStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/{correlationID}/status`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"correlationID"}}`, encodeURIComponent(String(requestParameters.correlationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileStatus200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns the status and any error reports for a file upload
     */
    async getFileStatus(requestParameters: GetFileStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFileStatus200Response> {
        const response = await this.getFileStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query for orgs linked to your org
     */
    async getLinkedOrgsRaw(requestParameters: GetLinkedOrgsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLinkedOrgs200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getLinkedOrgs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/linked-orgs`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLinkedOrgs200ResponseFromJSON(jsonValue));
    }

    /**
     * Query for orgs linked to your org
     */
    async getLinkedOrgs(requestParameters: GetLinkedOrgsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLinkedOrgs200Response> {
        const response = await this.getLinkedOrgsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check to see if this service is Live.
     */
    async getLiveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLive200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/live`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLive200ResponseFromJSON(jsonValue));
    }

    /**
     * Check to see if this service is Live.
     */
    async getLive(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLive200Response> {
        const response = await this.getLiveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get login url
     */
    async getLoginLinkRaw(requestParameters: GetLoginLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLoginLink200Response>> {
        const queryParameters: any = {};

        if (requestParameters.host !== undefined) {
            queryParameters['host'] = requestParameters.host;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLoginLink200ResponseFromJSON(jsonValue));
    }

    /**
     * Get login url
     */
    async getLoginLink(requestParameters: GetLoginLinkRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLoginLink200Response> {
        const response = await this.getLoginLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a link to log out of the system
     */
    async getLogoutLinkRaw(requestParameters: GetLogoutLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLogoutLink200Response>> {
        const queryParameters: any = {};

        if (requestParameters.host !== undefined) {
            queryParameters['host'] = requestParameters.host;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLogoutLink200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a link to log out of the system
     */
    async getLogoutLink(requestParameters: GetLogoutLinkRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLogoutLink200Response> {
        const response = await this.getLogoutLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get details for an org
     */
    async getOrgDetailsRaw(requestParameters: GetOrgDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrgDetails200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getOrgDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrgDetails200ResponseFromJSON(jsonValue));
    }

    /**
     * get details for an org
     */
    async getOrgDetails(requestParameters: GetOrgDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrgDetails200Response> {
        const response = await this.getOrgDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve Policies
     */
    async getPoliciesRaw(requestParameters: GetPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPolicies200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getPolicies.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.ownerID !== undefined) {
            queryParameters['ownerID'] = requestParameters.ownerID;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/policies`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPolicies200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve Policies
     */
    async getPolicies(requestParameters: GetPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPolicies200Response> {
        const response = await this.getPoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a Policy by id
     */
    async getPolicyRaw(requestParameters: GetPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPolicies200ResponsePoliciesInner>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getPolicy.');
        }

        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling getPolicy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/policies/{policyID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPolicies200ResponsePoliciesInnerFromJSON(jsonValue));
    }

    /**
     * Retrieve a Policy by id
     */
    async getPolicy(requestParameters: GetPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPolicies200ResponsePoliciesInner> {
        const response = await this.getPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statistics about a policy
     */
    async getPolicyStatisticsRaw(requestParameters: GetPolicyStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPolicyStatistics200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getPolicyStatistics.');
        }

        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling getPolicyStatistics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/policies/{policyID}/stats`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPolicyStatistics200ResponseFromJSON(jsonValue));
    }

    /**
     * Get statistics about a policy
     */
    async getPolicyStatistics(requestParameters: GetPolicyStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPolicyStatistics200Response> {
        const response = await this.getPolicyStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check to see if this service is Ready. If Ready, this service can accept new requests.
     */
    async getReadyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLive200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ready`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLive200ResponseFromJSON(jsonValue));
    }

    /**
     * Check to see if this service is Ready. If Ready, this service can accept new requests.
     */
    async getReady(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLive200Response> {
        const response = await this.getReadyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get specific template
     */
    async getTemplateRaw(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDatasource200ResponseMappedTemplatesInner>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getTemplate.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling getTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDatasource200ResponseMappedTemplatesInnerFromJSON(jsonValue));
    }

    /**
     * Get specific template
     */
    async getTemplate(requestParameters: GetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDatasource200ResponseMappedTemplatesInner> {
        const response = await this.getTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get template rules
     */
    async getTemplateRulesRaw(requestParameters: GetTemplateRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTemplateRules200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getTemplateRules.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/predicates`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTemplateRules200ResponseFromJSON(jsonValue));
    }

    /**
     * get template rules
     */
    async getTemplateRules(requestParameters: GetTemplateRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTemplateRules200Response> {
        const response = await this.getTemplateRulesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statistics about a template\'s containers
     */
    async getTemplateStatisticsRaw(requestParameters: GetTemplateStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTemplateStatistics200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling getTemplateStatistics.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling getTemplateStatistics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}/stats`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTemplateStatistics200ResponseFromJSON(jsonValue));
    }

    /**
     * Get statistics about a template\'s containers
     */
    async getTemplateStatistics(requestParameters: GetTemplateStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTemplateStatistics200Response> {
        const response = await this.getTemplateStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create ingestion log for the given datasource
     */
    async ingestDatasourceLogRaw(requestParameters: IngestDatasourceLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IngestDatasourceLog200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling ingestDatasourceLog.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling ingestDatasourceLog.');
        }

        if (requestParameters.correlationID === null || requestParameters.correlationID === undefined) {
            throw new runtime.RequiredError('correlationID','Required parameter requestParameters.correlationID was null or undefined when calling ingestDatasourceLog.');
        }

        if (requestParameters.adminEmail === null || requestParameters.adminEmail === undefined) {
            throw new runtime.RequiredError('adminEmail','Required parameter requestParameters.adminEmail was null or undefined when calling ingestDatasourceLog.');
        }

        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling ingestDatasourceLog.');
        }

        if (requestParameters.fileUrl === null || requestParameters.fileUrl === undefined) {
            throw new runtime.RequiredError('fileUrl','Required parameter requestParameters.fileUrl was null or undefined when calling ingestDatasourceLog.');
        }

        const queryParameters: any = {};

        if (requestParameters.adminEmail !== undefined) {
            queryParameters['adminEmail'] = requestParameters.adminEmail;
        }

        if (requestParameters.fileName !== undefined) {
            queryParameters['fileName'] = requestParameters.fileName;
        }

        if (requestParameters.fileUrl !== undefined) {
            queryParameters['fileUrl'] = requestParameters.fileUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}/ingestion/log/{correlationID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))).replace(`{${"correlationID"}}`, encodeURIComponent(String(requestParameters.correlationID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngestDatasourceLog200ResponseFromJSON(jsonValue));
    }

    /**
     * Create ingestion log for the given datasource
     */
    async ingestDatasourceLog(requestParameters: IngestDatasourceLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IngestDatasourceLog200Response> {
        const response = await this.ingestDatasourceLogRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View datasources for an org
     */
    async listDatasourcesRaw(requestParameters: ListDatasourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDatasources200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling listDatasources.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDatasources200ResponseFromJSON(jsonValue));
    }

    /**
     * View datasources for an org
     */
    async listDatasources(requestParameters: ListDatasourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDatasources200Response> {
        const response = await this.listDatasourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of templates visible to the given orgID
     */
    async listTemplatesRaw(requestParameters: ListTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTemplates200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling listTemplates.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerID !== undefined) {
            queryParameters['ownerID'] = requestParameters.ownerID;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.templateFieldId) {
            queryParameters['template_field_id'] = requestParameters.templateFieldId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTemplates200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of templates visible to the given orgID
     */
    async listTemplates(requestParameters: ListTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTemplates200Response> {
        const response = await this.listTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles completing login for a user with an auth code
     */
    async loginSuccessRaw(requestParameters: LoginSuccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginSuccess200Response>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling loginSuccess.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling loginSuccess.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login-success`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginSuccess200ResponseFromJSON(jsonValue));
    }

    /**
     * Handles completing login for a user with an auth code
     */
    async loginSuccess(requestParameters: LoginSuccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginSuccess200Response> {
        const response = await this.loginSuccessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handles completing logout for a user with an auth code
     */
    async logoutSuccessRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/logout-success`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handles completing logout for a user with an auth code
     */
    async logoutSuccess(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutSuccessRaw(initOverrides);
    }

    /**
     * Create a map from the given datasource to the given template
     */
    async mapTemplateRaw(requestParameters: MapTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MapTemplate201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling mapTemplate.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling mapTemplate.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling mapTemplate.');
        }

        if (requestParameters.templateMappingRequest === null || requestParameters.templateMappingRequest === undefined) {
            throw new runtime.RequiredError('templateMappingRequest','Required parameter requestParameters.templateMappingRequest was null or undefined when calling mapTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}/datasources/{datasourceID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MapTemplateRequestToJSON(requestParameters.templateMappingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapTemplate201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a map from the given datasource to the given template
     */
    async mapTemplate(requestParameters: MapTemplateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MapTemplate201Response> {
        const response = await this.mapTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orgOptionsGetRaw(requestParameters: OrgOptionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgOptionsGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/org-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgOptionsGet200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async orgOptionsGet(requestParameters: OrgOptionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgOptionsGet200Response> {
        const response = await this.orgOptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * list orgs
     */
    async orgsGetRaw(requestParameters: OrgsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgsGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgsGet200ResponseFromJSON(jsonValue));
    }

    /**
     * list orgs
     */
    async orgsGet(requestParameters: OrgsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgsGet200Response> {
        const response = await this.orgsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register a new organization
     */
    async registerNewOrgRaw(requestParameters: RegisterNewOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterNewOrg201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling registerNewOrg.');
        }

        if (requestParameters.registerNewOrgRequest === null || requestParameters.registerNewOrgRequest === undefined) {
            throw new runtime.RequiredError('registerNewOrgRequest','Required parameter requestParameters.registerNewOrgRequest was null or undefined when calling registerNewOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/register-new-org`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterNewOrgRequestToJSON(requestParameters.registerNewOrgRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterNewOrg201ResponseFromJSON(jsonValue));
    }

    /**
     * Register a new organization
     */
    async registerNewOrg(requestParameters: RegisterNewOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterNewOrg201Response> {
        const response = await this.registerNewOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject a container that has been issued to the current org.
     */
    async rejectContainerRaw(requestParameters: RejectContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizeContainer200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling rejectContainer.');
        }

        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling rejectContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/{containerID}/reject`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeContainer200ResponseFromJSON(jsonValue));
    }

    /**
     * Reject a container that has been issued to the current org.
     */
    async rejectContainer(requestParameters: RejectContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizeContainer200Response> {
        const response = await this.rejectContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove an admin from an org.
     */
    async removeAdminRaw(requestParameters: RemoveAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAdmin201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling removeAdmin.');
        }

        if (requestParameters.adminID === null || requestParameters.adminID === undefined) {
            throw new runtime.RequiredError('adminID','Required parameter requestParameters.adminID was null or undefined when calling removeAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/admins/{adminID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"adminID"}}`, encodeURIComponent(String(requestParameters.adminID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAdmin201ResponseFromJSON(jsonValue));
    }

    /**
     * Remove an admin from an org.
     */
    async removeAdmin(requestParameters: RemoveAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAdmin201Response> {
        const response = await this.removeAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update template field order
     */
    async reorderTemplateFieldsRaw(requestParameters: ReorderTemplateFieldsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EditTemplate200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling reorderTemplateFields.');
        }

        if (requestParameters.templateID === null || requestParameters.templateID === undefined) {
            throw new runtime.RequiredError('templateID','Required parameter requestParameters.templateID was null or undefined when calling reorderTemplateFields.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/templates/{templateID}/fields`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"templateID"}}`, encodeURIComponent(String(requestParameters.templateID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderTemplateFieldsRequestToJSON(requestParameters.templateFieldReorderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EditTemplate200ResponseFromJSON(jsonValue));
    }

    /**
     * Update template field order
     */
    async reorderTemplateFields(requestParameters: ReorderTemplateFieldsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EditTemplate200Response> {
        const response = await this.reorderTemplateFieldsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke a container that has been issued to the current org.
     */
    async revokeContainerRaw(requestParameters: RevokeContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizeContainer200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling revokeContainer.');
        }

        if (requestParameters.containerID === null || requestParameters.containerID === undefined) {
            throw new runtime.RequiredError('containerID','Required parameter requestParameters.containerID was null or undefined when calling revokeContainer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{orgID}/{containerID}/revoke`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"containerID"}}`, encodeURIComponent(String(requestParameters.containerID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeContainer200ResponseFromJSON(jsonValue));
    }

    /**
     * Revoke a container that has been issued to the current org.
     */
    async revokeContainer(requestParameters: RevokeContainerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizeContainer200Response> {
        const response = await this.revokeContainerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search by Field Values
     */
    async searchRaw(requestParameters: SearchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Search200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling search.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling search.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/search`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.query),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Search200ResponseFromJSON(jsonValue));
    }

    /**
     * Search by Field Values
     */
    async search(requestParameters: SearchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Search200Response> {
        const response = await this.searchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Query for orgs linked to your org
     */
    async selectOrgRaw(requestParameters: SelectOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAdmin201Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling selectOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/org-select/{orgID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAdmin201ResponseFromJSON(jsonValue));
    }

    /**
     * Query for orgs linked to your org
     */
    async selectOrg(requestParameters: SelectOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAdmin201Response> {
        const response = await this.selectOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an Agent
     */
    async updateAgentRaw(requestParameters: UpdateAgentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateAgent200Response>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling updateAgent.');
        }

        if (requestParameters.agentID === null || requestParameters.agentID === undefined) {
            throw new runtime.RequiredError('agentID','Required parameter requestParameters.agentID was null or undefined when calling updateAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/agents/{agentID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"agentID"}}`, encodeURIComponent(String(requestParameters.agentID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAgentRequestToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateAgent200ResponseFromJSON(jsonValue));
    }

    /**
     * Update an Agent
     */
    async updateAgent(requestParameters: UpdateAgentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateAgent200Response> {
        const response = await this.updateAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing datasource. Support for JSON field types here is toggled by the `showJsonFieldTypes` launch darkly flag.
     */
    async updateDatasourceRaw(requestParameters: UpdateDatasourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgID === null || requestParameters.orgID === undefined) {
            throw new runtime.RequiredError('orgID','Required parameter requestParameters.orgID was null or undefined when calling updateDatasource.');
        }

        if (requestParameters.datasourceID === null || requestParameters.datasourceID === undefined) {
            throw new runtime.RequiredError('datasourceID','Required parameter requestParameters.datasourceID was null or undefined when calling updateDatasource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.xSessionStore !== undefined && requestParameters.xSessionStore !== null) {
            headerParameters['X-Session-Store'] = String(requestParameters.xSessionStore);
        }

        const response = await this.request({
            path: `/orgs/{orgID}/datasources/{datasourceID}`.replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID))).replace(`{${"datasourceID"}}`, encodeURIComponent(String(requestParameters.datasourceID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDatasourceRequestToJSON(requestParameters.datasource),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing datasource. Support for JSON field types here is toggled by the `showJsonFieldTypes` launch darkly flag.
     */
    async updateDatasource(requestParameters: UpdateDatasourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDatasourceRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetAgentsLinkedToEntitySortByEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type GetAgentsLinkedToEntitySortByEnum = typeof GetAgentsLinkedToEntitySortByEnum[keyof typeof GetAgentsLinkedToEntitySortByEnum];
/**
 * @export
 */
export const GetAllAgentsVersionsSortByEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type GetAllAgentsVersionsSortByEnum = typeof GetAllAgentsVersionsSortByEnum[keyof typeof GetAllAgentsVersionsSortByEnum];
/**
 * @export
 */
export const GetContainersTemplateTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
} as const;
export type GetContainersTemplateTypeEnum = typeof GetContainersTemplateTypeEnum[keyof typeof GetContainersTemplateTypeEnum];
/**
 * @export
 */
export const GetContainersSortByEnum = {
    CreatedAt: 'createdAt',
    AuthorizedAt: 'authorizedAt',
    Name: 'name',
    Id: 'id'
} as const;
export type GetContainersSortByEnum = typeof GetContainersSortByEnum[keyof typeof GetContainersSortByEnum];
/**
 * @export
 */
export const GetContainersStateEnum = {
    Unissued: 'unissued',
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Revoked: 'revoked'
} as const;
export type GetContainersStateEnum = typeof GetContainersStateEnum[keyof typeof GetContainersStateEnum];
/**
 * @export
 */
export const GetPoliciesStateEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type GetPoliciesStateEnum = typeof GetPoliciesStateEnum[keyof typeof GetPoliciesStateEnum];
/**
 * @export
 */
export const ListTemplatesTypeEnum = {
    Folio: 'Folio',
    Merit: 'Merit',
    Persona: 'Persona'
} as const;
export type ListTemplatesTypeEnum = typeof ListTemplatesTypeEnum[keyof typeof ListTemplatesTypeEnum];
/**
 * @export
 */
export const ListTemplatesStatusEnum = {
    Live: 'live',
    Paused: 'paused'
} as const;
export type ListTemplatesStatusEnum = typeof ListTemplatesStatusEnum[keyof typeof ListTemplatesStatusEnum];
